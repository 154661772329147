<template>
    <the-env-var-listing :level="0" />
</template>

<script>
const TheEnvVarListing = () => import('@/components/TheEnvVarListing.vue')

export default {
    components: { TheEnvVarListing }
}
</script>
